@media print {
  html {
    width: 210mm;
    height: 270mm;
  }

  #footerContent {
    display: none;
  }

  #outdated {
    display: none;
  }

  .section {
    display: none;
  }
}
