/* titillium-web-regular - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/titillium-web/titillium-web-v6-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
    url('/assets/titillium-web/titillium-web-v6-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/titillium-web/titillium-web-v6-latin-regular.woff2') format('woff2'),
    url('/assets/titillium-web/titillium-web-v6-latin-regular.woff') format('woff'),
    url('/assets/titillium-web/titillium-web-v6-latin-regular.ttf') format('truetype'),
    url('/assets/titillium-web/titillium-web-v6-latin-regular.svg#TitilliumWeb') format('svg');
  /* Legacy iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web';
}

body,
html {
  overflow-x: hidden;
}